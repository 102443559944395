import React, { Component } from "react";

import "./App.scss";
import About from "./pages/about/about";
import Projects from "./pages/projects/projects";
import Home from "./pages/home/home";
import NavBar from "./navbar/NavBar";
import Blog from "./pages/blog/blog";
import { animateScroll as scroll } from "react-scroll";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      scrolling: false
    };
  }

  componentDidMount() {
    var self = this;
    const { scrolling } = this.state;

    document.addEventListener(
      "wheel",
      () => {
        if (window.pageYOffset === 0) {
          self.setState({ scrolling: false });
        } else if (window.pageYOffset > 10 && !scrolling) {
          self.setState({ scrolling: true });
        }
        return false;
      },
      true
    );
  }

  scrollToTop = () => {
    scroll.scrollToTop();
    setTimeout(() => {
      this.setState({ scrolling: false });
    }, 500);
  };

  handleSetActive = to => {
    this.setState({ scrolling: true });
  };

  render() {
    const { scrolling } = this.state;
    return (
      <div className="app-container" id="app-container">
        <NavBar
          scrolling={scrolling}
          handleSetActive={this.handleSetActive}
          scrollToTop={this.scrollToTop}
        />
        <div className="section-container" ref={this.myRef}>
          <Home />
          <About />
          <Blog />
          <Projects />
        </div>
      </div>
    );
  }
}
