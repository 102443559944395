import React, { Component } from "react";
import "./home.scss";

export default class Home extends Component {
  render() {
    return (
      <div className="home-background-parent position-relative">
        <div
          className="home-background-image"
        />
        <div
          className="home-background position-absolute d-flex flex-column justify-content-center
          align-items-center"
          id="home"
        >
          <div className="name-text">Hello, I'm Mahes ;)</div>
          <hr />
          <div className="desc-text pl-2 pr-2 text-center">
            Software Engineer living in Chennai, IN.{" "}
          </div>
          {/* <a
          className="mt-5"
          href="mailto:maheswaranapk@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button type="button" className="button-contact">
            Say Hello
          </button>
        </a> */}
        </div>
      </div>
    );
  }
}
