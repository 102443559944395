import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import "./projects.scss";

const PET_PROJECT_LIST = [
  {
    appName: "Stay Incognito (Beta)",
    description: "Simple peer to peer incognito chat.",
    appImageUrl: "https://incognito.appybot.in/apple-touch-icon.png",
    playStoreUrl: undefined,
    gitHubUrl: undefined,
    webUrl: "https://incognito.appybot.in/",
    tags: ["React", "JS", "Peerjs"],
  },
  {
    appName: "Talkie - TMDB Client",
    description:
      "Talkie is a place to explore Movies and TV shows with Clean UI.",
    appImageUrl: "https://talkie.appybot.in/favicon.ico",
    playStoreUrl: undefined,
    gitHubUrl: "https://github.com/maheswaranapk/Talkie",
    webUrl: "https://talkie.appybot.in/",
    tags: ["React", "JS"],
  },
  {
    appName: "Downloader for Instagram",
    description: "Android App  to download Instagram photos and videos",
    appImageUrl: "/assets/images/android_instagram_download.png",
    playStoreUrl:
      "https://play.google.com/store/apps/details?id=in.appybot.imagedownloader",
    gitHubUrl: undefined,
    webUrl: undefined,
    tags: ["Android", "Java"],
  },
  {
    appName: "Torrent Hash to Magnet Link",
    description: "A simple utility to convert Torrent Hash to Magnet Link.",
    appImageUrl: "/assets/images/web_magnet.webp",
    playStoreUrl: undefined,
    gitHubUrl: undefined,
    webUrl: "https://magnet-link.appybot.in/",
    tags: ["JS"],
  },
];

const OPEN_SOURCE_PROJECT_LIST = [
  {
    appName: "Android | Fade EditText View",
    description:
      "Simple Android Custom EditText which fade at the end when the text goes beyond the screen.",
    playStoreUrl: undefined,
    webUrl: undefined,
    gitHubUrl: "https://github.com/maheswaranapk/Android-Fade-EditText-View",
  },
  {
    appName: "Android | Cradle",
    description: "Android Implementation of Newton Cradle.",
    playStoreUrl: undefined,
    webUrl: undefined,
    gitHubUrl: "https://github.com/maheswaranapk/Android-Cradle",
  },
  {
    appName: "Android | Cortana Animation",
    description: "Demo of creating Microsoft Cortana Animations in Android.",
    playStoreUrl: undefined,
    webUrl: undefined,
    gitHubUrl: "https://github.com/maheswaranapk/Android-Cortana-Animation",
  },
  {
    appName: "CSS-Experiments",
    description: "Simple experiments with CSS",
    playStoreUrl: undefined,
    webUrl: "https://css.appybot.in/",
    gitHubUrl: "https://github.com/maheswaranapk/CSS-Experiments",
  },
  {
    appName: "Project-Euler-Solutions",
    description: "My Solutions to Project Euler Problems",
    playStoreUrl: undefined,
    webUrl: undefined,
    gitHubUrl: "https://github.com/maheswaranapk/Project-Euler-Solutions",
  },
];

const GitProject = ({ project }) => {
  return (
    <a
      href={project.gitHubUrl}
      className="col-12 col-lg-6 card-parent d-flex flex-row bg-transparent"
      target="_blank"
      without
      rel="noopener noreferrer"
    >
      <div className="card bg-white px-3 py-3 d-flex flex-row w-100">
        <div>
          <div className="app-name mb-1 d-flex flex-row align-items-center">
            <FontAwesomeIcon icon={faGithub} className="fa-github pr-2" />
            {project.appName}
          </div>
          <div className="app-description w-100">{project.description}</div>

          {project.webUrl && (
            <button
              type="button"
              className="btn btn-link pl-0"
              onClick={() => window.open(project.webUrl, "_blank")}
            >
              Show Demo
            </button>
          )}
        </div>
      </div>
    </a>
  );
};

const Project = ({ project }) => {
  return (
    <div className="card-parent col-12 col-lg-6 d-flex flex-row bg-transparent">
      <div className="card bg-white p-3 d-flex flex-row w-100">
        <img
          src={project.appImageUrl}
          className="app-image"
          alt="Logo"
          width="100px"
          height="100px"
        />
        <div className="d-flex flex-column justify-content-between ml-3">
          <div>
            <div className="app-name mb-1">{project.appName}</div>
            <div className="app-description w-100 mb-3">
              {project.description}
            </div>
          </div>
          <div classname="d-flex flex-row align-items-center flex-fill pb-1">
            {project.playStoreUrl && (
              <>
                <a
                  href="https://play.google.com/store/apps/details?id=in.appybot.imagedownloader"
                  className="cursor-pointer mb-2"
                >
                  <img
                    src={"/assets/images/available_on_android.png"}
                    alt="Logo"
                    target="_blank"
                    width="153px"
                    height="46px"
                    without
                    rel="noopener noreferrer"
                  />
                </a>
                <div className="mt-2">(10K+ downloads)</div>
              </>
            )}
            {project.webUrl && (
              <button
                type="button"
                className="btn btn-link px-0"
                onClick={() => window.open(project.webUrl, "_blank")}
              >
                Web App
              </button>
            )}
            {project.gitHubUrl && (
              <button
                type="button"
                className="btn btn-link pl-4"
                onClick={() => window.open(project.gitHubUrl, "_blank")}
              >
                Github Repo
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default class Projects extends Component {
  render() {
    return (
      <div className="w-100 project-container">
        <div className="w-100 pt-5 pb-5" id="projects">
          <div className="project-text w-100 text-center">Pet Projects</div>
          <div className="container mt-5 mb-5">
            <div className="row">
              {PET_PROJECT_LIST.map((project) => (
                <Project project={project} />
              ))}
            </div>
          </div>
          <div className="project-text w-100 text-center">
            Open Source Projects
          </div>
          <div className="container mt-5 mb-5">
            <div className="row">
              {OPEN_SOURCE_PROJECT_LIST.map((project) => (
                <GitProject project={project} />
              ))}
            </div>
          </div>
          <div className="container w-100">
            <small className="text-white ">
              *Google Play and the Google Play logo are trademarks of Google
              LLC.
            </small>
          </div>
        </div>

        <hr />
        <div className="footer w-100 text-center">
          <div className="mt-5 mb-4">
            Handcrafted by Me © 2020 | All rights reserved
          </div>
        </div>
      </div>
    );
  }
}
