import React, { Component } from "react";
import { Link } from "react-scroll";
import "./NavBar.scss";

export default class NavBar extends Component {
  scrollToTop = () => {
    this.props.scrollToTop();
  };
  render() {
    const { scrolling } = this.props;
    return (
      <nav
        id="navbar"
        className={`d-none d-md-block navbar navbar-expand-lg ${
          scrolling ? "" : "transparent"
        }`}
      >
        <div className="nav-content container-fluid">
          <div className="container p-0 d-flex flex-row justify-content-between align-items-center">
            <img
              src={"/assets/images/favicon.png"}
              className="nav-logo cursor-pointer"
              alt="Logo"
              width="45px"
              height="45px"
              onClick={this.scrollToTop}
            />
            <div
              class="collapse navbar-collapse flex-grow-0"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                  <Link
                    activeClass="active"
                    className="nav-link"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    onSetActive={this.props.handleSetActive}
                  >
                    About
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    activeClass="active"
                    className="nav-link"
                    to="blog"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    onSetActive={this.props.handleSetActive}
                  >
                    Recent Blogs
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    activeClass="active"
                    className="nav-link"
                    to="projects"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    onSetActive={this.props.handleSetActive}
                  >
                    Pet Projects
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
