import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faJs,
  faReact,
  faHtml5,
  faCss3Alt,
  faSass,
  faAndroid,
  faGithub,
  faLinkedinIn,
  faMediumM,
  faProductHunt,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import "./about.scss";

const Tag = ({ iconName, title }) => (
  <div className="tag col flex-grow-0">
    <FontAwesomeIcon icon={iconName} className="mr-2" />
    <span>{title}</span>
  </div>
);

const skilsList = [
  {
    iconName: faJs,
    title: "JavaScript",
  },
  {
    iconName: faReact,
    title: "React",
  },
  {
    iconName: faHtml5,
    title: "HTML",
  },
  {
    iconName: faCss3Alt,
    title: "CSS",
  },
  {
    iconName: faSass,
    title: "Sass",
  },
  {
    iconName: faReact,
    title: "React Native",
  },
  {
    iconName: faAndroid,
    title: "Android",
  },
];

export default class About extends Component {
  render() {
    return (
      <div className="w-100 about-container my-5" id="about">
        <div className="about-text w-100 text-center">About me</div>
        <div className="container mt-4">
          <div className="row">
            <div className="col-12 col-lg-6 d-flex flex-column justify-content-between align-items-center">
              <img
                src={"/assets/images/profile_pic_smal.webp"}
                className="rounded-circle mb-5"
                alt="Logo"
                onClick={this.scrollToTop}
                width="200px"
                height="200px"
              />
              <div className="text-center description mb-5">
                I am a Frontend Developer living in Chennai, currently working
                as Team Lead at Accenture.
              </div>
            </div>

            <div className="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
              <div className="w-100 mb-3 icon-parent row justify-content-center align-items-center ">
                {skilsList.map((skil) => (
                  <Tag iconName={skil.iconName} title={skil.title} />
                ))}
              </div>

              <div className="d-flex flex-row flex-wrap mt-5 float-center justify-content-center">
                <FontAwesomeIcon
                  icon={faGithub}
                  className="tag-social-icon cursor-pointer"
                  onClick={() =>
                    window.open("https://github.com/maheswaranapk", "_blank")
                  }
                />
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  className="tag-social-icon cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://in.linkedin.com/in/maheswaranapk",
                      "_blank"
                    )
                  }
                />
                <FontAwesomeIcon
                  icon={faMediumM}
                  className="tag-social-icon cursor-pointer"
                  onClick={() =>
                    window.open("https://medium.com/@maheswaranapk", "_blank")
                  }
                />

                <div className="w-100 d-lg-none" />
                <FontAwesomeIcon
                  icon={faProductHunt}
                  className="tag-social-icon cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://www.producthunt.com/@maheswaranapk/made",
                      "_blank"
                    )
                  }
                />
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="tag-social-icon cursor-pointer"
                  onClick={() =>
                    window.open("https://twitter.com/maheswaranapk", "_blank")
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
