import React, { Component } from "react";
import "./blog.scss";

const BLOG_LIST = [
  {
    name: "JS: Create Your Own Virtual DOM #DIY",
    description:
      " It is about my story of trying to implement a Minimal Virtual DOM using Plain JS.",
    imageURL: "/assets/images/Create Your Own Virtual DOM.webp",
    url:
      "https://medium.com/@maheswaranapk/js-create-your-own-virtual-dom-diy-70b278999acc",
  },
  {
    name: "Android: Create Your Own Custom View #DIY",
    description: "Things to understand before start writing Custom Views",
    imageURL: "/assets/images/Create Your Own Custom View.webp",
    url:
      "https://medium.com/@maheswaranapk/android-create-your-own-custom-view-diy-1632d560d546",
  },
  {
    name: "Android: Create Your Own Image Loading Library in Kotlin #DIY",
    description:
      "This post is for android beginners to understand the working of Image loading library by writing our own.",
    imageURL:
      "/assets/images/Create Your Own Image Loading Library in Kotlin.webp",
    url:
      "https://medium.com/@maheswaranapk/android-create-your-own-image-loading-library-in-kotlin-diy-bc7be9f286c5",
  },
];

export default class Blog extends Component {
  render() {
    return (
      <div className="w-100 blog-container">
        <div className="w-100 pt-5 pb-5" id="blog">
          <div className="project-text w-100 text-center">Recent Blogs</div>
          <div className="container mt-5 mb-3">
            <div className="row">
              {BLOG_LIST.map((blog) => (
                <div className="col-12 col-lg-4  mb-3 bg-transparent">
                  <div
                    className="blog-card d-flex flex-column p-4 bg-white cursor-pointer"
                    onClick={() => window.open(blog.url, "_blank")}
                  >
                    <img
                      src={blog.imageURL}
                      className="blog-image"
                      alt="Logo"
                      width="100%" height="100%"
                    />

                    <div className="blog-title mt-3 mb-2">{blog.name}</div>
                    <div className="blog-description mb-2">
                      {blog.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className="row float-right w-auto show-all cursor-pointer"
              onClick={() =>
                window.open("https://medium.com/@maheswaranapk", "_blank")
              }
            >
              See All Posts...
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}
